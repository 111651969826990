import { Col, Container, Row } from 'react-grid-system'
import { Link, useNavigate } from 'react-router-dom'

import { Content, PageHeader, Panel, Spinner, Steps } from '@/presentation/components'

import { useCards } from './hooks/useCards'
import { ArrowRightIcon } from './assets/ArrowRightIcon'
import { steps } from './utils/steps'

import { BackToList, Card, ContainerCards, StepsWrapper } from './styles'
import { useEffect } from 'react'

export const CardsSelection: React.FC = () => {
  const navigate = useNavigate()

  const { cards, isLoading } = useCards()

  useEffect(() => {
    if (cards.length === 1) {
      navigate('/recargas/novo_pedido_manual', {
        state: {
          operatorCardId: cards?.[0]?.operatorCardId,
          operatorCardName: cards?.[0]?.operatorCardName,
          operatorName: cards?.[0]?.operatorName,
        },
      })
    }
  }, [cards, navigate])

  return (
    <Content>
      <Panel noPadding>
        <PageHeader borderless={undefined} location={undefined}>
          Pedido de Recarga
        </PageHeader>
        <Container fluid style={{ padding: '32px' }}>
          {isLoading ? (
            <Spinner.Box>
              <Spinner />
            </Spinner.Box>
          ) : (
            <Row>
              <Col>
                <StepsWrapper>
                  <Steps currentStep={1} steps={steps} />
                </StepsWrapper>
              </Col>
            </Row>
          )}

          <Row justify='center'>
            <Col md='content'>
              <ContainerCards>
                {cards.map(({ operatorName, operatorCardName, operatorCardId }) => {
                  return (
                    <Card key={operatorCardName}>
                      <Link
                        to='/recargas/novo_pedido_manual'
                        state={{ operatorCardId, operatorCardName, operatorName, isAbleToChange: true }}
                      >
                        {/* <img src={image} /> */}
                        <div>
                          <p>{operatorCardName}</p>
                          <small>{operatorName}</small>
                        </div>

                        <ArrowRightIcon />
                      </Link>
                    </Card>
                  )
                })}
              </ContainerCards>
            </Col>
          </Row>
          <Row justify='center'>
            <Col md='content'>
              <BackToList>
                <Link to='/recargas'>Voltar a listagem</Link>
              </BackToList>
            </Col>
          </Row>
        </Container>
      </Panel>
    </Content>
  )
}
