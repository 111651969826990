import api from '@/infra/api'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { IUseBrokerOrderReturn } from './shared'
import { useNavigate } from 'react-router-dom'

interface LastOrderAPIReturn {
  pedidos: [
    {
      codigo: number
      dataCriacao: string
      id: number
      idCartaoOperadora: string
      idEmpresa: string
      idImportacao: string
      idRevenda: string
      importacaoId: number
      situacao: number
      numeroPedido: number
    },
  ]
}

const optionsMock = [
  {
    id: '231',
    label: 'Global',
    modelLink: process.env.REACT_APP_IMPORT_BROKER_RECHARGE_ORDER_FILE_MODEL_URL,
  },
]

export const useBrokerOrder = (): IUseBrokerOrderReturn => {
  const navigate = useNavigate()

  const { data, isLoading: isLoadingLastOrder } = useQuery<AxiosResponse<LastOrderAPIReturn>>({
    queryKey: ['lastBrokerOrder'],
    queryFn: () => api.get('rh-bff/brokerapi/pedidos?situacaoPedido=6'),
    refetchOnWindowFocus: false,
  })

  const { mutate: getImportCopyId, isLoading: getImportCopyIdLoading } = useMutation({
    mutationFn: (orderId: string) => api.post('rh-pedidos-bff/importacao/repetir', { idImportacao: orderId }),
    onSuccess: (res) => {
      navigate('/recargas/pedido_recarga_broker/revisao', {
        state: { importId: res?.data?.idImportacao, isDealer: false },
      })
    },
  })

  const redoLastOrder = useMutation({
    mutationFn: ({ orderId }: { orderId: string }) => {
      //remove the promise when unify the integrations
      return new Promise((resolve) => {
        resolve(getImportCopyId(orderId))
      })
    },
  })

  const uploadImportFile = useMutation({
    mutationFn: (payload: FormData) => api.post(`rh-bff/recharge/broker/import-order`, payload),
    onSuccess: (res) => {
      navigate('/recargas/pedido_recarga_broker/revisao', {
        state: { importId: res?.data, isDealer: false },
      })
    },
  })

  return {
    lastOrder:
      data?.data?.pedidos?.length > 0
        ? {
            code: data.data.pedidos[0].numeroPedido,
            id: data.data.pedidos[0].codigo,
            importationId: data.data.pedidos[0].idImportacao,
          }
        : null,
    layoutOptions: optionsMock,
    redoLastOrder,
    uploadImportFile,
    isLoading: {
      upload: uploadImportFile.isLoading || getImportCopyIdLoading,
      redoing: redoLastOrder.isLoading,
      lastOrder: isLoadingLastOrder,
      options: false,
    },
  }
}
