import { useQuery } from '@tanstack/react-query'

import api from '@/infra/api'

export const useCards = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['cards_recharge_manual'],
    queryFn: () => api.get(`rh-bff/recharge/new/operator-cards`),
  })

  return {
    cards: data?.data || [],
    isLoading,
  }
}
