import { useQuery } from '@tanstack/react-query'

import api from '@/infra/api'

export const useCards = () => {
  const { data, isLoading } = useQuery({
    queryKey: ['cards_first_via'],
    queryFn: () => api.get(`rh-bff/cards/new/operator-cards`),
  })

  return {
    cards: data?.data || [],
    isLoading,
  }
}
