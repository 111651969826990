import { useNavigate } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { FieldTableVirtualizedProps, EFieldMasks } from '@stationkim/front-ui'
import api from '@/infra/api'
import { useNotifications } from '@/main/hooks'
import { EMessageType } from '@/main/store'
import { downloadString } from '@/main/utils/functions'
import { width } from '@mui/system'

interface APIReturn<T> {
  dataCriacao: string
  id: number
  idEmpresa: string
  idImportacao: string
  idRevenda: string
  idUsuario: string
  nomeArquivo: string
  situacao: number
  tabela: {
    columns: any
    rows: T
  }
}

export const useImportResults = ({ orderId }: { orderId: string }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const notify = useNotifications()

  const brokerImportResultsQueryKey = ['brokerImportResults', orderId]
  const { data, ...rest } = useQuery<AxiosResponse<APIReturn<any>>>({
    queryKey: brokerImportResultsQueryKey,
    queryFn: async () => {
      const response = await api.get<APIReturn<any>>(`rh-bff/brokerapi/importacao/${orderId}`)
      if (response.data.tabela && response.data.id > 0) return response
      else throw new Error('Importação indisponível')
    },
    enabled: Boolean(orderId),
    retry: 10,
    retryDelay: 5000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
  const { mutate: createOrder, isLoading: createOrderLoading } = useMutation({
    mutationKey: ['createOrderTri', orderId],
    mutationFn: () => api.post(`rh-bff/brokerapi/importacao/gerarpedido`, { IdImportacao: orderId }),
    onSuccess: (res) => {
      navigate('/recargas/pedido_recarga_broker/pagamento', {
        state: { title: 'Recarga', isDealer: false, purchaseId: res?.data?.id },
      })
    },
  })

  const { mutate: getReport, isLoading: getReportLoading } = useMutation({
    mutationKey: ['getBrokerReport', orderId],
    mutationFn: () => api.get(`rh-pedidos-bff/importacao/relatorio-erro/${orderId}`),
    onSuccess: (res) => {
      if (res.data) downloadString(res.data, 'Relatório de erros da importação')
    },
  })

  const { mutate: deleteRow, isLoading: isDeleteRowLoading } = useMutation({
    mutationFn: async (rowId: string) => api.delete(`rh-bff/brokerapi/importacaoItem/${rowId}`),
    onMutate: async (rowId: string) => {
      const previousSnapshot = queryClient.getQueryData(brokerImportResultsQueryKey)

      queryClient.setQueryData(brokerImportResultsQueryKey, (oldData: any) => {
        const newData = { ...oldData }
        newData.data.tabela.rows = oldData.data.tabela.rows.filter((item) => item.id !== rowId)
        return newData
      })

      return { previousSnapshot }
    },
    onSuccess: () => {
      notify.push({ content: 'Registro removido', type: EMessageType.Success })
    },
    onError: (_err, _itemDeletado, context) => {
      queryClient.setQueryData(brokerImportResultsQueryKey, context.previousSnapshot)
    },
  })

  const { mutate: editRow, isLoading: isEditRowLoading } = useMutation({
    mutationFn: async (payload: any) => {
      const payloadNOError = payload
      payloadNOError.guidImportacao = orderId
      delete payloadNOError.errors
      return api.put(`rh-pedidos-bff/importacao/atualizarItem`, payload)
    },
    onSuccess: (res: any) => {
      try {
        queryClient.setQueryData(brokerImportResultsQueryKey, (oldData: any) => {
          const newData = { ...oldData }
          const indexOfEdited = newData.data.tabela.rows.findIndex((item) => item.id === res.data.id)
          newData.data.tabela.rows[indexOfEdited] = res.data
          return newData
        })
        notify.push({ content: 'Registro editado', type: EMessageType.Success })
      } catch (error) {
        console.log(error)
      }
    },
  })

  const organizeData = () => {
    if (!data?.data?.tabela)
      return {
        rows: [],
        employeesCount: 0,
        errorsCount: 0,
        columns: [],
        fileName: '',
      }

    let errorsCount = 0
    const columns: FieldTableVirtualizedProps['columns'] = data?.data?.tabela?.columns.map((column) => {
      return {
        ...column,
        key: column.columnValue,
        dataKey: column.columnValue,
        title: column.columnDisplayName,
        width: 220,
        editable: column.editable,
        rules: { required: 'Campo obrigatório' },
      }
    })

    columns[2].mask = EFieldMasks.Product
    columns[3].mask = EFieldMasks.Numeric
    columns[4].mask = EFieldMasks.Numeric
    columns[5].mask = EFieldMasks.Monetary
    columns[6].mask = EFieldMasks.Monetary
    const rows = data?.data?.tabela?.rows.map((row: any) => {
      let hasError = false
      if (row?.erros) {
        hasError = Object.keys(row.erros).some((key) => Boolean(row.erros[key]))
        if (hasError) errorsCount++
      }
      const finalRows = {
        ...row,
        errors: hasError ? row.erros : null,
      }
      delete finalRows.erros
      return finalRows
    })

    return {
      rows,
      errorsCount,
      employeesCount: rows?.length,
      columns,
      fileName: data?.data?.nomeArquivo,
    }
  }

  return {
    results: organizeData(),
    createOrder,
    createOrderLoading,
    getReport,
    getReportLoading,
    deleteRow,
    editRow,
    ...rest,
    isLoading: rest.isLoading || isDeleteRowLoading || isEditRowLoading || createOrderLoading,
  }
}
