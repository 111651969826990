import { getCardsOrderSlip } from '@/services/cardsServices'
import { useQuery } from '@tanstack/react-query'

export const useOrderSlip = ({ isSlipNeeded, id }: { isSlipNeeded: boolean; id: string }) => {
  const { data } = useQuery<any>({
    queryKey: [isSlipNeeded ? 'orderSlip' : null, id],
    queryFn: () => getCardsOrderSlip(id),
    retry: true,
  })

  return {
    boletoLink: data?.data || '',
  }
}
